.about-pic {
    height: 700px;
    border: solid rgb(52, 43, 30);
    border-width: 10px;
    position: relative;
    /* min-height: 500px; */
    background-attachment: fixed;
    /* background-position:top; */
    background-repeat: no-repeat;
    background-size: cover;
}

.bio {
    background-size: 300px;
    border-radius: 3px;
    opacity: 0;
    animation: showMe 6s forwards;
    transition: width 2s;
    transition-timing-function: ease-in;
    font-size: 30px;
    background-color: #c9b178c9;
    font-family: 'La Belle Aurore';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: end;
}
.text-box {
    border-radius: 3px;
    border: solid rgb(52, 43, 30);
    color: rgb(52, 43, 30);
    border-width: 3px;
    background-color: #c9b178;
    margin-top: 10px;
    /* font-family: 'La Belle Aurore'; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: end;
    text-align: center;
}

@keyframes showMe {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bio-para {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
}
.profile-pic {
    margin-bottom: 30px;
}
.row {
    margin-top: 20px;
}
.card {
    background-color: #C9B178!important;
    width: fit-content;
}
.skills {
    display: flex;
    flex-direction: column;
}
.skills-row {
    margin-top: 0!important;
    margin-right: 0!important;
    margin-left: 0!important;
    margin: 20px;
}
/* @media screen (max-width: 320px) {
    .skills {

    } */
/* } */