.name {
    font-family: 'La Belle Aurore';
    color: black;
    font-size: 60px;
}
.header {
    border-bottom: solid 10px rgb(52, 43, 30);
    position: sticky;
    padding: 0;
    background-color:#C9B178;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
iconify-icon {
    color: rgb(52, 43, 30);
}
iconify-icon:hover {
    opacity: 0.2;
}
.logo {
    width: 200px;
    height: 200px;
}

